export const stringToNumber = (value?: string) => {
    if (typeof value === 'string') {
        return parseFloat(value.replace(/,/g, '.').replace(/\s/g, '')) || 0
    }
    return value ? value : 0
}

export const getPennies = (value: string | number | undefined) => {
    if (typeof value === 'number') {
        return Math.round(value * 100)
    }
    if (typeof value === 'string') {
        return Math.round(stringToNumber(value) * 100)
    }
    return 0
}

export const getBasePercent = (value?: number) =>
    value !== undefined ? Math.floor(Number(value) * 1000) : undefined

export const roundByEpsilon = (value: number, divider: number = 100) =>
    Math.round((value + Number.EPSILON) * divider) / divider

export const getNoun = (number: number, one: string, two: string, five: string) => {
    let n = Math.abs(number)
    n %= 100
    if (n >= 5 && n <= 20) {
        return five
    }
    n %= 10
    if (n === 1) {
        return one
    }
    if (n >= 2 && n <= 4) {
        return two
    }
    return five
}
