import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import utc from 'dayjs/plugin/utc'
import moment, { Moment, isMoment } from 'moment'
import _ from 'lodash'
import { TCouponPaymentDate } from '../models'

dayjs.extend(customParseFormat)
dayjs.extend(utc)

export const getDateWithoutTime = (date?: string) => {
    if (typeof date !== 'string') {
        return undefined
    }
    const dateObject = dayjs.utc(date, 'DD.MM.YYYY')
    return dateObject.isValid() ? dateObject.unix() : undefined
}
export const getDateWithoutTimeString = (timestamp?: number) => {
    if (typeof timestamp !== 'number') {
        return ''
    }
    return dayjs.unix(timestamp).utc().format('DD.MM.YYYY')
}
export function getDateNowInUnix() {
    return dayjs().unix()
}

export const currentUnix = (): number => {
    return (Date.now() / 1000) | 0
}

export const safeToUtc = (
    time: Moment | string | undefined | number,
    unix?: boolean,
): string | number => {
    if (!time) {
        return ''
    }
    if (isMoment(time)) {
        if (unix) {
            return moment.utc(time).unix()
        }
        return time.utc().toString()
    }

    if (unix) {
        return moment.utc(+time).unix()
    }

    return moment.utc(time).toString()
}

export const getSortedUniqDates = (dates: TCouponPaymentDate[]) => {
  const uniqDates = _.uniqBy(dates, (date) => date.payment_date)
  return uniqDates.sort((a, b) => dayjs(a.payment_date, 'DD.MM.YYYY').unix() - dayjs(b.payment_date, 'DD.MM.YYYY').unix())
}
